<template>
  <div>
    <div ref="vantaRef" style="width:100vw;height:100vh"></div>
    <div class="IM-loginbox">
      <div class="IM-loginbox-title">
        <div>IM Sign In</div>
        <t-button @click="submit" variant="text" theme="default" ghost>Sign In</t-button>
      </div>
      <div class="IM-loginbox-desc">Sign into your Resistered Account</div>
      <div class="IM-loginbox-line"></div>
      <div class="IM-loginbox-iputerpart">
        <div class="IM-loginbox-username-txt">Username</div>
        <div class="IM-loginbox-username-inputer">
          <input @keyup.enter="submit" class="IM-inputer" type="text" v-model="username" />
        </div>
        <div class="IM-loginbox-password-txt">Password</div>
        <div class="IM-loginbox-password-inputer">
          <input @keyup.enter="submit" class="IM-inputer" type="password" v-model="password" />
        </div>
      </div>
      <div class="IM-loginbox-signinbt"></div>
    </div>
  </div>
</template>

<script>
import * as THREE from "three";
import CLOUDS from "vanta/src/vanta.clouds";
import connectionmock from "../assets/scripts/connectionmock";

export default {
  data() {
    return {
      username: "",
      password: "",
      memberArr: JSON.parse(localStorage.getItem("connectionData"))
    };
  },
  mounted() {
     if (!localStorage.getItem("connectionData")) {
      this.initData();
    }
    this.vantaEffect = CLOUDS({
      el: this.$refs.vantaRef,
      THREE: THREE,
      mouseControls: true,
      touchControls: true,
      gyroControls: false,
      speed: 0.8,
      skyColor: 0x87a5ca
    });
  },
  beforeDestroy() {
    if (this.vantaEffect) {
      this.vantaEffect.destroy();
    }
  },
  methods: {
     initData() {
      localStorage.clear();
      localStorage.setItem("connectionData", JSON.stringify(connectionmock));
      localStorage.setItem("chatsData", JSON.stringify([]));
      this.memberArr = JSON.parse(localStorage.getItem("connectionData"))
    },
    submit() {
      if (!this.username) {
        this.$message.warning("请先输入登录用户名");
        return;
      }
      if (!this.password) {
        this.$message.warning("请先输入登录密码");
        return;
      }
      let targetaccount = this.memberArr.find(
        item => item.username == this.username
      );
      let targetaccountIndex = this.memberArr.findIndex(
        item => item.username == this.username
      );
      if (!targetaccount) {
        this.$message.error("未找到相关用户");
      } else {
        // console.log(targetaccount);
        localStorage.setItem("loginAccount", JSON.stringify(targetaccount));
        localStorage.setItem("AccountIndex", JSON.stringify(targetaccountIndex));
        setTimeout(() => {
          this.$router.push("/Index");
        });
      }
    }
  }
};
</script>

<style lang="less" scoped>
.IM-loginbox {
  z-index: 99;
  position: fixed;
  width: 500px;
  height: 400px;
  right: 40px;
  top: 20%;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 8px;
  /* filter: blur(0.5); */
  backdrop-filter: blur(10px);
  padding: 20px;
  box-sizing: border-box;
  .IM-loginbox-iputerpart {
    margin-top: 40px;
    .IM-loginbox-username-inputer {
      height: 48px;
      width: 100%;
      border-radius: 12px;
      opacity: 0.5;
      background: #ffffff;
      margin-top: 12px;
      display: flex;
      align-items: center;
    }
    .IM-loginbox-password-inputer {
      height: 48px;
      width: 100%;
      border-radius: 12px;
      opacity: 0.5;
      background: #ffffff;
      margin-top: 12px;
      display: flex;
      align-items: center;
    }
    .IM-loginbox-username-txt {
      color: #ffffff;
      font-size: 20px;
      font-weight: 600;
    }
    .IM-loginbox-password-txt {
      margin-top: 24px;

      color: #ffffff;
      font-size: 20px;
      font-weight: 600;
    }
  }
  .IM-loginbox-line {
    width: 38px;
    height: 4px;
    opacity: 1;
    background: #ffffff;
    margin-top: 18px;
    border-radius: 10px;
  }
  .IM-loginbox-desc {
    font-size: 20px;
    color: #fff;
    margin-top: 8px;
  }
  .IM-loginbox-title {
    font-weight: bold;
    font-size: 40px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
.IM-inputer {
  width: 100%;
  border: none;
  height: 100%;
  background: rgba(0, 0, 0, 0);
  font-size: 20px;
  padding: 0 20px;
}
.IM-inputer:focus {
  border: none;
  outline: none;
}
</style>
