const connectionmock =  [
    {
        "username":'im001',
        "id": 4460,
        "nickname": "袁超",
        "phonenum": "18127128236",
        "avatarnum": 6,
        "leftmsg": 1,
        "identity": [
            {
                "id": 4470,
                "nickname": "林强",
                "company": "河北省 沧州市",
                "avatarnum": 6,
                "msgnum": 0,
                "chats": [
                    {
                        "id": 4480,
                        "name": "雷芳",
                        "type": 1,
                        "avatarnum": 12,
                        "content": "你量开提复时厂问利无反然运里公共。",
                        "time": "1718259124",
                        "msgnum": 0,
                        "otherinfo": {
                            "remarks": "nostrud",
                            "tags": [
                                "马构保",
                                "手区常第",
                                "集史"
                            ],
                            "tel": "18683424188",
                            "type": 1,
                            "from": "石霞",
                            "addtime": "1718259124",
                            "lasttime": "1718259124"
                        },
                        "interaction": [
                            {
                                "type": 1,
                                "time": "1718259124",
                                "tagname": "多",
                                "txt": "名步样他军容九题",
                                "especialtype": 3652545695043336
                            }
                        ]
                    },
                    {
                        "id": 4490,
                        "name": "周强",
                        "type": 2,
                        "avatarnum": 16,
                        "content": "多小真所确可果步面龙口外般走。",
                        "time": "1718259124",
                        "msgnum": 0,
                        "otherinfo": {
                            "remarks": "laboris laborum consectetur deserunt",
                            "tags": [
                                "间值县构"
                            ],
                            "tel": "18649899287",
                            "type": 2,
                            "from": "万敏",
                            "addtime": "1718259124",
                            "lasttime": "1718259124"
                        },
                        "interaction": [
                            {
                                "type": 1,
                                "time": "1718259124",
                                "tagname": "表",
                                "txt": "立记劳世即再之传",
                                "especialtype": 5210780002128532
                            },
                            {
                                "type": 2,
                                "time": "1718259124",
                                "tagname": "放",
                                "txt": "音各确所天年处",
                                "especialtype": 2204579506635626
                            }
                        ]
                    }
                ]
            },
            {
                "id": 4500,
                "nickname": "龚勇",
                "company": "四川省 雅安市",
                "avatarnum": 12,
                "msgnum": 0,
                "chats": [
                    {
                        "id": 4510,
                        "name": "黎娜",
                        "type": 1,
                        "avatarnum": 4,
                        "content": "史办己小许日发通工及走统根头。",
                        "time": "1718259124",
                        "msgnum": 0,
                        "otherinfo": {
                            "remarks": "Lorem",
                            "tags": [
                                "方林子",
                                "件人都斗",
                                "上做料型"
                            ],
                            "tel": "18121308354",
                            "type": 2,
                            "from": "郭娟",
                            "addtime": "1718259124",
                            "lasttime": "1718259124"
                        },
                        "interaction": [
                            {
                                "type": 2,
                                "time": "1718259124",
                                "tagname": "观",
                                "txt": "派山区但增派",
                                "especialtype": 6251458914753006
                            },
                            {
                                "type": 2,
                                "time": "1718259124",
                                "tagname": "观",
                                "txt": "南改片温处则京",
                                "especialtype": 2605177088601486
                            },
                            {
                                "type": 2,
                                "time": "1718259124",
                                "tagname": "相",
                                "txt": "族所多手革那",
                                "especialtype": 8606998137515160
                            }
                        ]
                    },
                    {
                        "id": 4520,
                        "name": "郭勇",
                        "type": 2,
                        "avatarnum": 16,
                        "content": "参证改市类干然最感接多天资国术件。",
                        "time": "1718259124",
                        "msgnum": 0,
                        "otherinfo": {
                            "remarks": "consequat voluptate ea ut anim",
                            "tags": [
                                "厂学",
                                "支照太以",
                                "切书第"
                            ],
                            "tel": "19831816671",
                            "type": 2,
                            "from": "蒋超",
                            "addtime": "1718259124",
                            "lasttime": "1718259124"
                        },
                        "interaction": [
                            {
                                "type": 1,
                                "time": "1718259124",
                                "tagname": "好",
                                "txt": "越是型边单克候素马期",
                                "especialtype": 227577218253760
                            },
                            {
                                "type": 2,
                                "time": "1718259124",
                                "tagname": "路",
                                "txt": "话叫今非最写导消高细",
                                "especialtype": 78108115168934
                            },
                            {
                                "type": 2,
                                "time": "1718259124",
                                "tagname": "委",
                                "txt": "二会走走叫议整",
                                "especialtype": 2972177809146092
                            }
                        ]
                    }
                ]
            },
            {
                "id": 4530,
                "nickname": "苏强",
                "company": "山东省 莱芜市",
                "avatarnum": 10,
                "msgnum": 0,
                "chats": [
                    {
                        "id": 4540,
                        "name": "周秀兰",
                        "type": 2,
                        "avatarnum": 14,
                        "content": "高提交研它满规格开必维走说即外温电。",
                        "time": "1718259124",
                        "msgnum": 0,
                        "otherinfo": {
                            "remarks": "in sunt laborum dolore",
                            "tags": [
                                "以按报"
                            ],
                            "tel": "18129331500",
                            "type": 2,
                            "from": "曹艳",
                            "addtime": "1718259124",
                            "lasttime": "1718259124"
                        },
                        "interaction": [
                            {
                                "type": 1,
                                "time": "1718259124",
                                "tagname": "革",
                                "txt": "时需并关设论克油",
                                "especialtype": 1282868529575984
                            },
                            {
                                "type": 1,
                                "time": "1718259124",
                                "tagname": "准",
                                "txt": "至将经交专二",
                                "especialtype": 5378886328220884
                            }
                        ]
                    },
                    {
                        "id": 4550,
                        "name": "赵涛",
                        "type": 2,
                        "avatarnum": 19,
                        "content": "没东气体院江料低声名系得特由团历。",
                        "time": "1718259124",
                        "msgnum": 0,
                        "otherinfo": {
                            "remarks": "et consequat",
                            "tags": [
                                "类部备",
                                "前因形",
                                "已真几共"
                            ],
                            "tel": "19836857675",
                            "type": 1,
                            "from": "傅勇",
                            "addtime": "1718259124",
                            "lasttime": "1718259124"
                        },
                        "interaction": [
                            {
                                "type": 2,
                                "time": "1718259124",
                                "tagname": "深",
                                "txt": "约千任义之",
                                "especialtype": 2719603703656098
                            },
                            {
                                "type": 1,
                                "time": "1718259124",
                                "tagname": "色",
                                "txt": "系海回省按",
                                "especialtype": 6121477922966214
                            }
                        ]
                    },
                    {
                        "id": 4560,
                        "name": "董洋",
                        "type": 2,
                        "avatarnum": 18,
                        "content": "程气号加消改太战花走油反步林小多。",
                        "time": "1718259124",
                        "msgnum": 0,
                        "otherinfo": {
                            "remarks": "aute enim in laborum",
                            "tags": [
                                "组验比与"
                            ],
                            "tel": "18618497827",
                            "type": 2,
                            "from": "于涛",
                            "addtime": "1718259124",
                            "lasttime": "1718259124"
                        },
                        "interaction": [
                            {
                                "type": 1,
                                "time": "1718259124",
                                "tagname": "五",
                                "txt": "育活战所大动教则要",
                                "especialtype": 335594261298338
                            },
                            {
                                "type": 2,
                                "time": "1718259124",
                                "tagname": "在",
                                "txt": "精北以老光必家议管装",
                                "especialtype": 4277701341932716
                            }
                        ]
                    }
                ]
            }
        ]
    },
    {
        "username":'im002',
        "id": 5060,
        "nickname": "陈伟",
        "phonenum": "18698068623",
        "avatarnum": 18,
        "leftmsg": 5,
        "identity": [
            {
                "id": 5070,
                "nickname": "贺涛",
                "company": "江西省 赣州市",
                "avatarnum": 18,
                "msgnum": 0,
                "chats": [
                    {
                        "id": 5080,
                        "name": "高杰",
                        "type": 1,
                        "avatarnum": 6,
                        "content": "感器合决导离布温难值科非理多每值。",
                        "time": "1718259174",
                        "msgnum": 0,
                        "otherinfo": {
                            "remarks": "fugiat veniam ex dolore consequat",
                            "tags": [
                                "法少"
                            ],
                            "tel": "18689783090",
                            "type": 1,
                            "from": "卢洋",
                            "addtime": "1718259174",
                            "lasttime": "1718259174"
                        },
                        "interaction": [
                            {
                                "type": 2,
                                "time": "1718259174",
                                "tagname": "前",
                                "txt": "府该内战然口际全有那",
                                "especialtype": 2057438602107062
                            },
                            {
                                "type": 1,
                                "time": "1718259174",
                                "tagname": "更",
                                "txt": "角线表中很把记们南",
                                "especialtype": 8633681499880880
                            }
                        ]
                    },
                    {
                        "id": 5090,
                        "name": "陆敏",
                        "type": 1,
                        "avatarnum": 8,
                        "content": "米火集张样小们代思然众般按提过口铁。",
                        "time": "1718259174",
                        "msgnum": 0,
                        "otherinfo": {
                            "remarks": "veniam dolore quis culpa",
                            "tags": [
                                "半己",
                                "际办"
                            ],
                            "tel": "19818673735",
                            "type": 1,
                            "from": "雷明",
                            "addtime": "1718259174",
                            "lasttime": "1718259174"
                        },
                        "interaction": [
                            {
                                "type": 2,
                                "time": "1718259174",
                                "tagname": "对",
                                "txt": "达任电高多思改效记",
                                "especialtype": 7573856475738078
                            }
                        ]
                    }
                ]
            },
            {
                "id": 5100,
                "nickname": "白霞",
                "company": "湖南省 永州市",
                "avatarnum": 14,
                "msgnum": 0,
                "chats": [
                    {
                        "id": 5110,
                        "name": "李刚",
                        "type": 1,
                        "avatarnum": 13,
                        "content": "目军思今更多传看断公受这增离。",
                        "time": "1718259174",
                        "msgnum": 0,
                        "otherinfo": {
                            "remarks": "do",
                            "tags": [
                                "验片派叫",
                                "样业"
                            ],
                            "tel": "18141345655",
                            "type": 1,
                            "from": "田敏",
                            "addtime": "1718259174",
                            "lasttime": "1718259174"
                        },
                        "interaction": [
                            {
                                "type": 2,
                                "time": "1718259174",
                                "tagname": "百",
                                "txt": "过土类可将",
                                "especialtype": 2467663947651638
                            },
                            {
                                "type": 2,
                                "time": "1718259174",
                                "tagname": "眼",
                                "txt": "律多适接形",
                                "especialtype": 7233108252168730
                            },
                            {
                                "type": 1,
                                "time": "1718259174",
                                "tagname": "使",
                                "txt": "相断土强当立数",
                                "especialtype": 6023837355684038
                            }
                        ]
                    },
                    {
                        "id": 5120,
                        "name": "尹丽",
                        "type": 1,
                        "avatarnum": 8,
                        "content": "严等备养温色党何参音入将。",
                        "time": "1718259174",
                        "msgnum": 0,
                        "otherinfo": {
                            "remarks": "proident reprehenderit aliqua pariatur dolore",
                            "tags": [
                                "影结九",
                                "各她"
                            ],
                            "tel": "18186894175",
                            "type": 1,
                            "from": "赖平",
                            "addtime": "1718259174",
                            "lasttime": "1718259174"
                        },
                        "interaction": [
                            {
                                "type": 2,
                                "time": "1718259174",
                                "tagname": "北",
                                "txt": "关明直先也内件南",
                                "especialtype": 163875463608588
                            }
                        ]
                    },
                    {
                        "id": 5130,
                        "name": "苏秀兰",
                        "type": 1,
                        "avatarnum": 6,
                        "content": "果情带温入受以局百济张务外件。",
                        "time": "1718259174",
                        "msgnum": 0,
                        "otherinfo": {
                            "remarks": "dolor reprehenderit Duis",
                            "tags": [
                                "段道色"
                            ],
                            "tel": "18628747172",
                            "type": 2,
                            "from": "于勇",
                            "addtime": "1718259174",
                            "lasttime": "1718259174"
                        },
                        "interaction": [
                            {
                                "type": 1,
                                "time": "1718259174",
                                "tagname": "争",
                                "txt": "己文公基细斗界",
                                "especialtype": 985026088216850
                            }
                        ]
                    }
                ]
            },
            {
                "id": 5140,
                "nickname": "杜勇",
                "company": "新疆维吾尔自治区 吐鲁番地区",
                "avatarnum": 16,
                "msgnum": 0,
                "chats": [
                    {
                        "id": 5150,
                        "name": "曾洋",
                        "type": 2,
                        "avatarnum": 19,
                        "content": "再江快该件七始法团思最完米其。",
                        "time": "1718259174",
                        "msgnum": 0,
                        "otherinfo": {
                            "remarks": "reprehenderit Excepteur",
                            "tags": [
                                "极取争且",
                                "高什用"
                            ],
                            "tel": "13554520848",
                            "type": 2,
                            "from": "吴敏",
                            "addtime": "1718259174",
                            "lasttime": "1718259174"
                        },
                        "interaction": [
                            {
                                "type": 2,
                                "time": "1718259174",
                                "tagname": "见",
                                "txt": "商只济完采她",
                                "especialtype": 4175142664870232
                            },
                            {
                                "type": 2,
                                "time": "1718259174",
                                "tagname": "人",
                                "txt": "儿型温意出才几斯",
                                "especialtype": 5919243645406606
                            },
                            {
                                "type": 1,
                                "time": "1718259174",
                                "tagname": "己",
                                "txt": "结厂质目按间收",
                                "especialtype": 5187465036485948
                            }
                        ]
                    }
                ]
            }
        ]
    },
    {
        "username":'im003',
        "id": 5250,
        "nickname": "阎霞",
        "phonenum": "18171855583",
        "avatarnum": 4,
        "leftmsg": 9,
        "identity": [
            {
                "id": 5260,
                "nickname": "侯娜",
                "company": "上海 上海市",
                "avatarnum": 6,
                "msgnum": 0,
                "chats": [
                    {
                        "id": 5270,
                        "name": "龙桂英",
                        "type": 1,
                        "avatarnum": 5,
                        "content": "但圆如老知各美活张办们强专。",
                        "time": "1718259184",
                        "msgnum": 0,
                        "otherinfo": {
                            "remarks": "non",
                            "tags": [
                                "经主眼",
                                "关这以更"
                            ],
                            "tel": "18684851313",
                            "type": 1,
                            "from": "姚娜",
                            "addtime": "1718259184",
                            "lasttime": "1718259184"
                        },
                        "interaction": [
                            {
                                "type": 1,
                                "time": "1718259184",
                                "tagname": "制",
                                "txt": "展两机特己方过日",
                                "especialtype": 6318030675359462
                            },
                            {
                                "type": 2,
                                "time": "1718259184",
                                "tagname": "它",
                                "txt": "万才色规之来划又们表",
                                "especialtype": 2923185987847138
                            }
                        ]
                    }
                ]
            },
            {
                "id": 5280,
                "nickname": "汤磊",
                "company": "澳门特别行政区 澳门半岛",
                "avatarnum": 19,
                "msgnum": 0,
                "chats": [
                    {
                        "id": 5290,
                        "name": "陆勇",
                        "type": 1,
                        "avatarnum": 18,
                        "content": "十复回复有力段那完段化全按界广细务况。",
                        "time": "1718259184",
                        "msgnum": 0,
                        "otherinfo": {
                            "remarks": "fugiat aliquip",
                            "tags": [
                                "色周应业",
                                "海想内市",
                                "许动保"
                            ],
                            "tel": "19847238653",
                            "type": 2,
                            "from": "熊超",
                            "addtime": "1718259184",
                            "lasttime": "1718259184"
                        },
                        "interaction": [
                            {
                                "type": 1,
                                "time": "1718259184",
                                "tagname": "给",
                                "txt": "日速可油说",
                                "especialtype": 929127665316448
                            },
                            {
                                "type": 1,
                                "time": "1718259184",
                                "tagname": "此",
                                "txt": "备器区山济始度教",
                                "especialtype": 175365154288146
                            }
                        ]
                    },
                    {
                        "id": 5300,
                        "name": "周芳",
                        "type": 1,
                        "avatarnum": 2,
                        "content": "选系般指好离各族大争地论。",
                        "time": "1718259184",
                        "msgnum": 0,
                        "otherinfo": {
                            "remarks": "voluptate",
                            "tags": [
                                "确记集",
                                "经行组"
                            ],
                            "tel": "18624454264",
                            "type": 2,
                            "from": "戴洋",
                            "addtime": "1718259184",
                            "lasttime": "1718259184"
                        },
                        "interaction": [
                            {
                                "type": 2,
                                "time": "1718259184",
                                "tagname": "白",
                                "txt": "写色区认候写",
                                "especialtype": 4906192188533540
                            },
                            {
                                "type": 1,
                                "time": "1718259184",
                                "tagname": "来",
                                "txt": "角下往儿写复么数",
                                "especialtype": 7874528146342322
                            }
                        ]
                    },
                    {
                        "id": 5310,
                        "name": "汪芳",
                        "type": 2,
                        "avatarnum": 18,
                        "content": "治多省的压都月导三复感做为界明。",
                        "time": "1718259184",
                        "msgnum": 0,
                        "otherinfo": {
                            "remarks": "officia irure reprehenderit elit qui",
                            "tags": [
                                "两最具",
                                "特命电"
                            ],
                            "tel": "18130530418",
                            "type": 1,
                            "from": "顾桂英",
                            "addtime": "1718259184",
                            "lasttime": "1718259184"
                        },
                        "interaction": [
                            {
                                "type": 1,
                                "time": "1718259184",
                                "tagname": "厂",
                                "txt": "每成酸马厂院么老",
                                "especialtype": 815743340688178
                            }
                        ]
                    }
                ]
            }
        ]
    },
    {
        "username":'im004',
        "id": 5830,
        "nickname": "万磊",
        "phonenum": "19874652857",
        "avatarnum": 7,
        "leftmsg": 17,
        "identity": [
            {
                "id": 5840,
                "nickname": "曹军",
                "company": "四川省 攀枝花市",
                "avatarnum": 19,
                "msgnum": 0,
                "chats": [
                    {
                        "id": 5850,
                        "name": "郑霞",
                        "type": 1,
                        "avatarnum": 17,
                        "content": "近达结走织土理金较使联去记快图律里例。",
                        "time": "1718259196",
                        "msgnum": 0,
                        "otherinfo": {
                            "remarks": "sit",
                            "tags": [
                                "育实革厂",
                                "向统转至"
                            ],
                            "tel": "18164843278",
                            "type": 2,
                            "from": "邱平",
                            "addtime": "1718259196",
                            "lasttime": "1718259196"
                        },
                        "interaction": [
                            {
                                "type": 1,
                                "time": "1718259196",
                                "tagname": "没",
                                "txt": "马每话整加百面较",
                                "especialtype": 1423704628894290
                            },
                            {
                                "type": 2,
                                "time": "1718259196",
                                "tagname": "写",
                                "txt": "具本面转集也术天满识",
                                "especialtype": 411974369667376
                            },
                            {
                                "type": 2,
                                "time": "1718259196",
                                "tagname": "场",
                                "txt": "走白行象因",
                                "especialtype": 1804388129964752
                            }
                        ]
                    },
                    {
                        "id": 5860,
                        "name": "曹磊",
                        "type": 2,
                        "avatarnum": 8,
                        "content": "重办产二号权非关土总节马。",
                        "time": "1718259196",
                        "msgnum": 0,
                        "otherinfo": {
                            "remarks": "laboris sunt",
                            "tags": [
                                "部全",
                                "除没"
                            ],
                            "tel": "18160427153",
                            "type": 1,
                            "from": "卢秀兰",
                            "addtime": "1718259196",
                            "lasttime": "1718259196"
                        },
                        "interaction": [
                            {
                                "type": 1,
                                "time": "1718259196",
                                "tagname": "率",
                                "txt": "海维数只除联",
                                "especialtype": 109938583505084
                            }
                        ]
                    },
                    {
                        "id": 5870,
                        "name": "蔡磊",
                        "type": 2,
                        "avatarnum": 4,
                        "content": "花最却被小声史叫重山低标就性精她分。",
                        "time": "1718259196",
                        "msgnum": 0,
                        "otherinfo": {
                            "remarks": "deserunt consectetur qui",
                            "tags": [
                                "代满"
                            ],
                            "tel": "19824311131",
                            "type": 2,
                            "from": "易静",
                            "addtime": "1718259196",
                            "lasttime": "1718259196"
                        },
                        "interaction": [
                            {
                                "type": 2,
                                "time": "1718259196",
                                "tagname": "族",
                                "txt": "图定统要现证",
                                "especialtype": 6087906883979852
                            },
                            {
                                "type": 1,
                                "time": "1718259196",
                                "tagname": "速",
                                "txt": "日命实通后派品回",
                                "especialtype": 3753826706473572
                            }
                        ]
                    }
                ]
            },
            {
                "id": 5880,
                "nickname": "冯敏",
                "company": "贵州省 安顺市",
                "avatarnum": 7,
                "msgnum": 0,
                "chats": [
                    {
                        "id": 5890,
                        "name": "马艳",
                        "type": 1,
                        "avatarnum": 4,
                        "content": "或律认别金分酸能采和表张表马调口治。",
                        "time": "1718259196",
                        "msgnum": 0,
                        "otherinfo": {
                            "remarks": "amet sint non laborum",
                            "tags": [
                                "使商受议",
                                "形照合先"
                            ],
                            "tel": "18111665898",
                            "type": 2,
                            "from": "卢强",
                            "addtime": "1718259196",
                            "lasttime": "1718259196"
                        },
                        "interaction": [
                            {
                                "type": 2,
                                "time": "1718259196",
                                "tagname": "民",
                                "txt": "议火议图越思九劳众",
                                "especialtype": 1886082458111604
                            }
                        ]
                    },
                    {
                        "id": 5900,
                        "name": "杨刚",
                        "type": 1,
                        "avatarnum": 5,
                        "content": "教无南程拉都满局东引价加格便。",
                        "time": "1718259196",
                        "msgnum": 0,
                        "otherinfo": {
                            "remarks": "esse aliqua",
                            "tags": [
                                "知取第头",
                                "的走便"
                            ],
                            "tel": "13844483346",
                            "type": 2,
                            "from": "史芳",
                            "addtime": "1718259196",
                            "lasttime": "1718259196"
                        },
                        "interaction": [
                            {
                                "type": 1,
                                "time": "1718259196",
                                "tagname": "存",
                                "txt": "响没飞联关应克可管",
                                "especialtype": 6406156113829704
                            }
                        ]
                    }
                ]
            },
            {
                "id": 5910,
                "nickname": "朱娟",
                "company": "甘肃省 甘南藏族自治州",
                "avatarnum": 14,
                "msgnum": 0,
                "chats": [
                    {
                        "id": 5920,
                        "name": "廖磊",
                        "type": 2,
                        "avatarnum": 7,
                        "content": "事实队克业思向一员对存却管根进成。",
                        "time": "1718259196",
                        "msgnum": 0,
                        "otherinfo": {
                            "remarks": "occaecat Lorem ipsum",
                            "tags": [
                                "条开了会",
                                "据马队身"
                            ],
                            "tel": "18655775067",
                            "type": 1,
                            "from": "郭艳",
                            "addtime": "1718259196",
                            "lasttime": "1718259196"
                        },
                        "interaction": [
                            {
                                "type": 1,
                                "time": "1718259196",
                                "tagname": "西",
                                "txt": "分真空酸把有难便及",
                                "especialtype": 1429907994101484
                            },
                            {
                                "type": 1,
                                "time": "1718259196",
                                "tagname": "写",
                                "txt": "马包拉种声调",
                                "especialtype": 4213474734705838
                            },
                            {
                                "type": 1,
                                "time": "1718259196",
                                "tagname": "属",
                                "txt": "认线市精用院位矿",
                                "especialtype": 7574283792441290
                            }
                        ]
                    }
                ]
            }
        ]
    }
]

export default connectionmock;