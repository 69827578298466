import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Tdesign from 'tdesign-vue'
import 'tdesign-vue/es/style/index.css';
import "/node_modules/swiper/swiper.min.css"
import service from '@/utils/request';
import './assets/css/animation.css'; 

Vue.prototype.$request = service
Vue.use(Tdesign)


Vue.config.productionTip = false


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
