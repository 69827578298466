<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  mounted() {
    //获取本地存储占用
    console.log(
      "本地存储量",
      Math.floor(this.getLocalStorageSize() / 1024),
      "kb == " + (this.getLocalStorageSize() / 1024 / 1024).toFixed(2),
      "Mb"
    );
    if ((this.getLocalStorageSize() / 1024 / 1024).toFixed(2) < 4) {

      console.log('\x1b[32m%s\x1b[0m','本地存储空间状态优良');
    }
    if ((this.getLocalStorageSize() / 1024 / 1024).toFixed(2) >= 4&&(this.getLocalStorageSize() / 1024 / 1024).toFixed(2) <4.5) {
      console.log('\x1b[33m%s\x1b[0m','本地存储空间不足！');
    }
    if ((this.getLocalStorageSize() / 1024 / 1024).toFixed(2) >= 4.5) {
      console.log('\x1b[31m%s\x1b[0m','本地存储空间严重不足！');
    }
  },
  methods: {
    getLocalStorageSize() {
      let size = 0;
      for (let i = 0; i < localStorage.length; i++) {
        const key = localStorage.key(i);
        const value = localStorage.getItem(key);
        size += key.length + (value && value.length) * 2; // 2 bytes per character if the value is a string
      }
      return size;
    }
  }
};
</script>


<style>
* {
  margin: 0;
  padding: 0;
}
</style>

